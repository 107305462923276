@charset "utf-8";

@import '../../../node_modules/bulma/sass/utilities/_all.sass';
@import '../../../node_modules/bulma/sass/layout/hero.sass';

img {
  width: 100%;
}

.hero-image {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
  url('../../assets/four-chickens.jpg') center/cover no-repeat;
}

.hero-title {
  text-align: center;
  font-size: 2rem;
}

@media (min-width: 1024px) {
  .hero-title {
    font-size: 2.5rem;
  }
}

.shop-now-btn {
  text-align: center;
}

#shop-now {
  font-weight: 600;
  &:hover {
    background-color: #606060;
  }
}